import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Strong, Button, Em, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Bold & Classic Barbershop
			</title>
			<meta name={"description"} content={"Mükemmel Üretim, Tek Seferde Tek Kesim"} />
			<meta property={"og:title"} content={"Bold & Classic Barbershop"} />
			<meta property={"og:description"} content={"Mükemmel Üretim, Tek Seferde Tek Kesim"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(4, 16, 41, 0.74) 0%,rgba(4, 16, 41, 0.79) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11:51:17.746Z) bottom/cover repeat scroll padding-box" padding="160px 0 160px 0">
			<Override slot="text">
				Geleneğin Stille Buluştuğu Yer
			</Override>
			<Override slot="text1" width="90%">
				Zamansız bakım sanatını takdir edenler için bir cennet olan Bold & Classic Barbershop'a hoş geldiniz.
			</Override>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="box" />
			<Override slot="link" href="/services" />
		</Components.Hero>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				Bold & Classic Barbershop Hakkında
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Zengin mirasımız ve mükemmelliğe olan bağlılığımızla gurur duyuyoruz. Kuruluşumuz sadece saç kestirilecek bir yer değil; hikayelerin paylaşıldığı, dostlukların kurulduğu ve güvenin geliştirildiği bir topluluk merkezidir.
					</Text>
					<Text margin="0px 0px 2rem 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Benzersiz Yaklaşımımız
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Bizi diğerlerinden ayıran şey, zanaata olan sarsılmaz bağlılığımızdır. Berberlerimiz yalnızca eğitimli profesyoneller değil, aynı zamanda her saç kesimini yapım aşamasındaki bir başyapıt olarak gören tutkulu sanatçılardır.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						10+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						yıllık deneyim
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						5000+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						memnun müşteri
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						20+
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						hizmet türü
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 0px 0px 160px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
				order="1"
				text-align="right"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Neden Bizi Seçmelisiniz?
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Benzersiz bir bakım deneyimi sunan yetenekli berberlerimiz, kaliteli ürünlerimiz ve rahatlatıcı ortamımız ile farkımızı hissedin.
				</Text>
				<Link
					href="#"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Hizmetlerimiz
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
				justify-content="center"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Uzman Berberler
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Yüksek vasıflı profesyonellerden oluşan ekibimiz, her zaman birinci sınıf hizmet almanızı sağlar.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Kaliteli Ürünler
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Sadece saçınızı ve cildinizi besleyen, kendinizi yenilenmiş hissetmenizi sağlayan en iyi bakım ürünlerini kullanıyoruz.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Rahatlatıcı Ortam
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Rahatlayabileceğiniz ve bakım seansınızın tadını çıkarabileceğiniz sakin bir ortam sunuyoruz.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						4
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Kişiselleştirilmiş Servis
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Stil tercihlerinizi ve bakım ihtiyaçlarınızı anlamaya zaman ayırıyoruz.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-primary" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="--headline2" color="--light">
				Bakım Deneyiminizi Keşfedin
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Saç Kesimi
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Klasik ve modern tarzlarda mükemmel saç kesimleri sunuyoruz.
						<br />
						<br />
						Saç kesimi ve yıkama dahil.
						<br />
						<br />
						Kişiselleştirilmiş stil önerileri ile en iyi görünümünüzü elde edin.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Tıraş ve Sakal Bakımı
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Kusursuz bir tıraş veya özenli sakal bakımı hizmetleri.
						<br />
						<br />
						Sıcak havlu ve özel bakım ürünleri ile keyifli bir deneyim.
						<br />
						<br />
						Sakal şekillendirme ve düzeltme hizmetleri.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Özel Bakım Hizmetleri
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Cilt bakımı, masaj ve diğer özel bakım hizmetleri.
						<br />
						<br />
						Kişiye özel bakım planları ile yenilenmiş hissedin.
						<br />
						<br />
						Sadece en kaliteli ürünler kullanılır.
					</Text>
				</Box>
			</Box>
			<Button
				margin="2rem 0px 0px 0px"
				box-shadow="10px 10px 10px -5px rgba(249, 242, 242, 0.1)"
				type="link"
				text-transform="uppercase"
				text-align="center"
				text-decoration-line="initial"
				border-width="1px"
				border-style="solid"
				href="/services"
			>
				<Strong>
					Tüm Hizmetler
				</Strong>
			</Button>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-3.jpg?v=2024-07-09T11:51:17.711Z) center center/cover no-repeat"
			min-height="60vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					<Em>
						Hazır mısınız?
					</Em>
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="60%"
				>
					Cesur ve Klasik Berber Dükkanı'nda klasik çekiciliğin ve modern zarafetin hakim olduğu bir dünyaya adım atın.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Bize Katılın!
					</Span>
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});